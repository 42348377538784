import React, { useEffect } from 'react';
import Script from 'next/script';
import { getChatInitData } from 'lib/chat/getChatInitData';
import { isomorphicGetCookie } from 'IsomorphicUtils';

export const ZoomChat: React.FC = () => {
  const userId = parseInt(isomorphicGetCookie('userid') as string, 10);
  const userToken = isomorphicGetCookie('userToken') as string;

  useEffect(() => {
    getChatInitData(userId, userToken)
      .then((chatData) => {
        // @ts-expect-error attaching custom variable to window object
        window.formswiftZoomChatInitData = chatData;
      })
      .catch((error: Error) => {
        // eslint-disable-next-line no-console
        console.error('Error getting chat initialization data:', error);
      });
  });

  return (
    <Script
      data-testid="zoom-chat-client-script"
      src="https://meta-kdevarapalli-coder-jarvis.dev.corp.dropbox.com/pithos/zoom_chat_client"
    />
  );
};
