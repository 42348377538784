import { chatClient } from 'lib/chat/ChatClient';
import { useEffect } from 'react';
import { useDecision } from '@optimizely/react-sdk';

export const useChat = () => {
  const [{ variationKey: chatVariationKey }] = useDecision('dbx_zoom_chat');

  useEffect(() => {
    if (!chatVariationKey || chatVariationKey === 'off') {
      chatClient.enableChat();
    } else {
      const checkForChatClient = async () => {
        // eslint-disable-next-line no-underscore-dangle
        if (window._DBX_ZOOM_CHAT_CLIENT) {
          // eslint-disable-next-line no-underscore-dangle
          await window._DBX_ZOOM_CHAT_CLIENT.showFormSwiftChat();
        } else {
          setTimeout(checkForChatClient, 100);
        }
      };

      checkForChatClient();
    }
  }, [chatVariationKey]);
};
